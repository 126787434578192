body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-router] {
  box-shadow: none !important;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #ff462b;
  --amplify-components-button-primary-hover-background-color: #810d00;
  --amplify-components-button-primary-focus-background-color: #810d00;
  --amplify-components-button-link-color: #ff462b;
  --amplify-components-tabs-item-active-border-color: #ff462b;
  --amplify-components-tabs-item-hover-color: #210000;
  --amplify-components-tabs-item-active-color: #210000;
  --amplify-components-tabs-item-focus-color: black;
  --amplify-components-link-hover-color: #ff8a7f;
}

.amplify-button[data-variation='primary'] {
  --amplify-components-button-primary-background-color: #ff462b;
  --amplify-components-button-primary-hover-background-color: #810d00;
  --amplify-components-button-primary-focus-background-color: #810d00;
}

[data-amplify-authenticator] [data-amplify-form] .amplify-heading {
  font-size: var(--amplify-components-heading-5-font-size);
  font-weight: var(--amplify-font-weights-bold);
}

.amplify-tabs {
  display: none;
}

.amplify-label {
  display: none !important;
}
